import { useCallback, useLayoutEffect, useRef } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import lottie, { AnimationItem } from 'lottie-web';

import DancingBookLottieAnimation from '@/@assets/dancing-book-lottie-animation.json';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  ProjectCheckoutServicesProvider,
  useProjectCheckoutServices,
} from '@/modules/project-checkout/project-checkout-services.context.';
import { getCheckoutGetStartedPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services.tsx';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { CheckoutSession, useCheckoutSession } from '@/services/api/checkout';

import { Container, Content, Divider, Header, Wordmark } from './ProjectCheckoutSuccessPage.styles';

function getShareASaleUrl(session: CheckoutSession): string {
  const url = new URL('https://www.shareasale.com/sale.cfm');
  url.searchParams.set('tracking', session.invoiceNumber ?? '');
  url.searchParams.set('amount', String(session.paid));
  url.searchParams.set('merchantID', '146714');
  url.searchParams.set('transtype', 'sale');

  if (session.couponId !== null) {
    url.searchParams.set('couponId', session.couponId);
  }

  return url.toString();
}

function InternalProjectCheckoutSuccessPage() {
  const navigate = useNavigate();
  const user = useUser();
  const [searchParams] = useSearchParams();
  const isMobile = useIsMobileViewport();
  const { checkoutAnalyticsService } = useServices();

  const { projectCheckoutFormRepository } = useProjectCheckoutServices();

  const checkoutSessionId = searchParams.get('checkout-session-id') ?? searchParams.get('checkoutSessionId');
  const checkoutSession = useCheckoutSession(checkoutSessionId);
  const projectId = searchParams.get('projectId');
  const checkoutType = (searchParams.get('checkout-type') ?? searchParams.get('projectType')) as
    | 'BIOGRAPHY'
    | 'AUTOBIOGRAPHY'
    | 'BABYBOOK'
    | 'FREE'
    | 'BUNDLE';

  // Lottie animation
  const animationRef = useRef<AnimationItem | null>(null);
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      if (animationRef.current) {
        animationRef.current.destroy();
        animationRef.current = null;
      }
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: DancingBookLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  useLayoutEffect(() => {
    if (checkoutSession.data == null) return;

    const { price, paid } = checkoutSession.data;
    // If the user successfully paid, there will be an invoiceNumber
    if (checkoutSession.data.invoiceNumber != null) {
      checkoutAnalyticsService.onCheckoutSuccess(checkoutSession.data.invoiceNumber, price, paid);
    }

    // Cleaning checkout form data after payment
    projectCheckoutFormRepository.clear();

    if (checkoutType === 'FREE' && paid == 0) {
      navigate(getCheckoutGetStartedPath(searchParams), { replace: true });
      return;
    }

    const animationTimeout = setTimeout(() => {
      if (checkoutType === 'BUNDLE') {
        if (user === null) {
          navigate('/checkout/survey?' + searchParams.toString(), { replace: true });
          return;
        }
        navigate(getCheckoutGetStartedPath(searchParams), { replace: true });
        return;
      }

      if (user != null) {
        navigate(getCheckoutGetStartedPath(searchParams), { replace: true });
      } else if (user == null) {
        navigate('/checkout/survey?' + searchParams.toString(), { replace: true });
      } else {
        navigate(`/?${searchParams.toString()}`, { replace: true });
      }
    }, 4000);

    return () => clearTimeout(animationTimeout);
  }, [
    searchParams,
    user,
    projectCheckoutFormRepository,
    navigate,
    checkoutAnalyticsService,
    checkoutSession.data,
    projectId,
    checkoutType,
  ]);

  // Redirect to the start of the flow if no checkout session id
  // is present or if the id is invalid
  if (checkoutSessionId === null) {
    return <Navigate to="/checkout" />;
  }
  if (checkoutSession.error) {
    return <Navigate to="/checkout" />;
  }

  if (!checkoutSession.data) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Header isMobile={isMobile}>
        <Wordmark />
      </Header>
      <Divider />

      <Content>
        <div ref={lottieRef} />
        <RMText type="serif" size="xl" color="on-surface-primary">
          Payment successful!
        </RMText>
      </Content>

      <img src={getShareASaleUrl(checkoutSession.data)} width="1" height="1" />
    </Container>
  );
}

export function ProjectCheckoutSuccessPage() {
  return (
    <RementoPage type="empty">
      <ProjectCheckoutServicesProvider>
        <InternalProjectCheckoutSuccessPage />
      </ProjectCheckoutServicesProvider>
    </RementoPage>
  );
}
