import { PropsWithChildren, ReactNode } from 'react';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';

import { Container, Footer, Main, ScrollArea, Wordmark } from './ProjectCheckoutMobileLayout.styles';

type ProjectCheckoutMobileLayout = PropsWithChildren<{
  Stepper: ReactNode;
  Header: ReactNode;
  ContinueButton: ReactNode;
}>;

export function ProjectCheckoutMobileLayout({
  Stepper,
  ContinueButton,
  Header,
  children,
}: ProjectCheckoutMobileLayout) {
  return (
    <Container>
      <Wordmark destination="marketing" />
      {Header}
      <ScrollArea>
        <Main>
          {Stepper}
          <RMSpacer spacing="md" direction="column" />
          {children}
        </Main>
      </ScrollArea>
      <Footer>{ContinueButton}</Footer>
    </Container>
  );
}
