import { useEffect } from 'react';

import { getInputInteraction, getInputValue, setInputValue, watchInputValue } from '@/modules/form/input';
import { genitiveCase } from '@/utils/genitiveCase';

import { BabybookProjectCheckoutBookForm } from '../components/BabybookProjectCheckoutBookForm/BabybookProjectCheckoutBookForm';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

export function BabybookProjectCheckoutBookStepContainer() {
  const manager = useProjectCheckoutManager();

  useEffect(() => {
    goToStep(manager, 'details');

    const updateProjectName = (subjectFirstName: string | null | undefined) => {
      const projectNameIsDirty = getInputInteraction(manager.form, 'name').isDirty;
      if (!projectNameIsDirty) {
        setInputValue(manager.form, 'name', `${genitiveCase(subjectFirstName || 'Baby')} First Year`);
      }
    };

    updateProjectName(getInputValue(manager.form, 'subject.firstName'));
    return watchInputValue(manager.form, 'subject.firstName', updateProjectName);
  }, [manager]);

  return <BabybookProjectCheckoutBookForm form={manager.form} />;
}
