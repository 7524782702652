import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  svg {
    color: var(--primary);
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--border-hairline);
  margin: var(--spacing-lg) 0;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: var(--on-surface-primary);
`;
