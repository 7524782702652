import { useMemo } from 'react';
import dayjs from 'dayjs';

import { RMInput } from '@/components/RMInput';
import { RMPhoneInput } from '@/components/RMPhoneInput/RMPhoneInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';

import { ProjectCheckoutForm } from '../../project-checkout.form';

import { Container, Field, FormGrid, SubHeader } from './BiographyProjectCheckoutDeliveryForm.styles';

export interface BiographyProjectCheckoutDeliveryFormProps {
  form: ProjectCheckoutForm;
}

export function BiographyProjectCheckoutDeliveryForm({ form }: BiographyProjectCheckoutDeliveryFormProps) {
  const isMobile = useIsMobileViewport();

  const minDatePickerDate = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return dayjs(today).format('YYYY-MM-DD');
  }, []);

  return (
    <Container>
      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Gift delivery details
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <InputContainer form={form} path="recipient.person.firstName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="storyteller-first-name"
                label={isMobile ? 'Your gift recipient’s first name' : 'Your gift recipient’s name'}
                placeholder="Their first name"
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="recipient.person.lastName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="storyteller-last-name"
                // \u00A0 === &nbsp;
                // We need this to keep both inputs at the same position.
                label={isMobile ? 'Your gift recipient’s last name' : '\u00A0'}
                placeholder="Their last name"
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="recipient.email">
          {(props) => (
            <Field size="large">
              <RMInput
                id="storyteller-email"
                label="Your gift recipient’s email"
                placeholder="first.last@example.com"
                autoCapitalize="none"
                type="email"
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputController form={form} path="recipient.phone">
          {(props) => (
            <Field size="large">
              <RMPhoneInput label="Your gift recipient’s phone number (optional)" {...props} />
              <RMSpacer spacing="xs" direction="column" />
              <RMText size="xs" type="sans" color="on-surface-tertiary">
                Remento sends helpful reminders via text (currently US numbers only). Text STOP or disable in settings
                to unsubscribe. Msg & data rates may apply.
              </RMText>
            </Field>
          )}
        </InputController>
        <InputContainer form={form} path="gift.sendOn">
          {(props) => (
            <Field size="large">
              <RMInput
                id="storyteller-start-date"
                type="date"
                label="Email my gift on"
                placeholder="XX/XX/XXXX"
                min={minDatePickerDate}
                {...props}
              />
              <RMSpacer spacing="xs" direction="column" />
              <RMText size="xs" type="sans" color="on-surface-tertiary">
                Remento will send your gift recipient a welcome email with your gift message on this date.
              </RMText>
            </Field>
          )}
        </InputContainer>

        <SubHeader type="serif" size={isMobile ? 'm' : 'l'} color="on-surface-primary">
          Add a gift message
        </SubHeader>

        <InputContainer form={form} path="gift.from">
          {(props) => (
            <Field size="large">
              <RMInput id="gift-from" label="From" placeholder="Your name + anyone else the gift is from" {...props} />
            </Field>
          )}
        </InputContainer>

        <InputContainer form={form} path="gift.message">
          {(props) => (
            <Field size="large">
              <RMInput id="gift-message" label="Gift message" textarea {...props} />
            </Field>
          )}
        </InputContainer>
      </FormGrid>
      <RMSpacer spacing="md" direction="column" />
      <RMText type="sans" size="xs" color="on-surface-primary">
        Note: You’ll be able to edit this information until the send date.
      </RMText>
    </Container>
  );
}
