import { useEffect } from 'react';

import { BiographyProjectCheckoutDeliveryForm } from '../components/BiographyProjectCheckoutDeliveryForm/BiographyProjectCheckoutDeliveryForm';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

export function BiographyProjectCheckoutDeliveryStepContainer() {
  const manager = useProjectCheckoutManager();

  useEffect(() => {
    goToStep(manager, 'delivery');
  }, [manager]);

  return <BiographyProjectCheckoutDeliveryForm form={manager.form} />;
}
