import { useEffect } from 'react';

import { FreeProjectCheckoutDetailsForm } from '../components/FreeProjectCheckoutDetailsForm/FreeProjectCheckoutDetailsForm';
import { useStorytellerOptions } from '../hooks/useStorytellerOptions';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

export function FreeProjectCheckoutDetailsStepContainer() {
  const manager = useProjectCheckoutManager();

  // Queries
  const storytellerOptions = useStorytellerOptions();

  useEffect(() => {
    goToStep(manager, 'details');
  }, [manager]);

  return <FreeProjectCheckoutDetailsForm form={manager.form} storytellers={storytellerOptions ?? []} />;
}
