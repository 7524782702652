import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/Services';

export function useMarketingSourcesQuery() {
  const { marketingSourceService } = useServices();
  return useQuery({
    queryKey: ['marketing-sources'],
    queryFn: () => marketingSourceService.getAll(),
  });
}
