import { useEffect } from 'react';

import { BiographyProjectCheckoutReview } from '../components/BiographyProjectCheckoutReview/BiographyProjectCheckoutReview';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

import { ProjectCheckoutSummaryContainer } from './ProjectCheckoutSummary.container';

export function BiographyProjectCheckoutReviewStepContainer() {
  const manager = useProjectCheckoutManager();

  useEffect(() => {
    goToStep(manager, 'review');
  }, [manager]);

  return (
    <BiographyProjectCheckoutReview
      form={manager.form}
      onEditHostInfo={() => goToStep(manager, 'contact')}
      onEditStorytellerInfo={() => goToStep(manager, 'delivery')}
      CheckoutSummary={<ProjectCheckoutSummaryContainer manager={manager} sticky />}
    />
  );
}
