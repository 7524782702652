import EmailValidator from 'email-validator';
import { z } from 'zod';

import { createUserPhoneSchema } from '@/utils/phone-number';

import { Form } from '../form/@types';
import { DeepPartial } from '../form/@types-utils';
import { createForm } from '../form/form';

function createProjectCheckoutUserSchema() {
  return z.object({
    person: z.object({
      firstName: z.string().min(1, 'Enter their first name'),
      lastName: z.string().min(1, 'Enter their last name'),
    }),
    email: z.string().refine(EmailValidator.validate, { message: 'Enter a valid email address' }),
    phone: createUserPhoneSchema().nullish(),
  });
}

export function createProjectCheckoutFormSchema() {
  return z.object({
    /**
     * The type of the project.
     */
    type: z.union([z.literal('BIOGRAPHY'), z.literal('AUTOBIOGRAPHY'), z.literal('BABYBOOK'), z.literal('FREE')]),
    /**
     * A stripe coupon to be applied to the order.
     */
    coupon: z.string().nullish(),
    /**
     * The ID of the user who made the referral."
     */
    referrerUserId: z.string().nullish(),
    /**
     * The owner of the project.
     */
    owner: createProjectCheckoutUserSchema(),
    /**
     * The additional products to be purchased with the project.
     */
    products: z.object({
      ebook: z.boolean(),
      books: z.number(),
    }),
    /**
     * The gift information.
     * This is only used when the project is a `BIOGRAPHY`.
     */
    gift: z.object({
      from: z.string().min(1, 'Enter the gift sender'),
      message: z
        .string()
        .min(1, 'Enter the gift message')
        .max(450, 'The gift message can only contain up to 450 characters.'),
      sendOn: z.string().transform((dateStringified, ctx) => {
        if (!dateStringified) {
          ctx.addIssue({ code: 'invalid_date', message: 'Select today or a future date' });
          return -1;
        }

        const date = new Date(dateStringified);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date.getTime() < today.getTime()) {
          ctx.addIssue({ code: 'invalid_date', message: 'Select today or a future date' });
          return -1;
        }

        return date.getTime();
      }),
    }),
    /**
     * The subject of the project. (idk why this is called recipient).
     * This is only used when the project is a `BIOGRAPHY`.
     */
    recipient: createProjectCheckoutUserSchema(),
    /**
     * The name of the project.
     * This is only used when the project is a `BABYBOOK` or `FREE`.
     */
    name: z.string().min(1, 'Enter the project title'),
    /**
     * The subject of the project.
     * This is only used when the project is a `BABYBOOK`.
     */
    subject: z.object({
      firstName: z.string().nullish(),
    }),
    /**
     * The person ID of the recipient of the project.
     * This is only used when the project is a `FREE`.
     */
    recipientPersonId: z.string(),
    /**
     * The person ID of the owner of the subscription.
     * This is only used when the project is a `FREE`.
     */
    subscriptionOwnerPersonId: z.string(),
  });
}

export type ProjectCheckoutFormInput = z.input<ReturnType<typeof createProjectCheckoutFormSchema>>;
export type ProjectCheckoutFormOutput = z.output<ReturnType<typeof createProjectCheckoutFormSchema>>;
export type ProjectCheckoutForm = Form<ProjectCheckoutFormInput, ProjectCheckoutFormOutput>;

export function createProjectCheckoutForm(defaultValues?: DeepPartial<ProjectCheckoutFormInput>): ProjectCheckoutForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createProjectCheckoutFormSchema(),
  });
}
