import ProjectOnlyCheckoutSrc from '@/assets/project-only-checkout.png';
import BabyBookSrc from '@/assets/remento-baby-book.webp';
import BookSrc from '@/assets/remento-book.webp';

import { ProjectCheckoutProducts } from '../../project-checkout.manager';

import { ProjectCheckoutSummaryType } from './ProjectCheckoutSummary.types';

export function getProjectCheckoutSummaryImage(type: ProjectCheckoutSummaryType, products: ProjectCheckoutProducts) {
  if (type === 'BABYBOOK') {
    return { src: BabyBookSrc, portrait: false };
  }
  if (products.books == 0 && products.ebook == false) {
    return { src: ProjectOnlyCheckoutSrc, portrait: true };
  }
  return { src: BookSrc, portrait: false };
}

export function getProjectCheckoutSummaryPreloadImages(type: ProjectCheckoutSummaryType) {
  if (type !== 'FREE') {
    return [];
  }
  return [ProjectOnlyCheckoutSrc, BookSrc];
}
