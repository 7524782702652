import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';

export const StyledProjectCheckoutSelection = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const Header = styled.div`
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--inverse-surface);
  width: 100%;
  height: 56px;
  flex: 0 0 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
`;

export const HeaderLogo = styled.img`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
    position: absolute;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: var(--spacing-2xl) 0;
  gap: var(--spacing-2xl);
  height: 100%;
  max-width: calc(45rem + var(--spacing-lg));
  margin: 0 auto;
  position: relative;

  body[data-mobile='true'] & {
    gap: var(--spacing-xl);
    padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md) var(--spacing-md);
    max-width: unset;
    max-width: unset;
    margin: none;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  text-align: center;

  body[data-mobile='true'] & {
    text-align: left;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);

  body[data-mobile='true'] & {
    height: 100%;
    flex-direction: column;
    gap: var(--spacing-md);
  }
`;

export const Buttons = styled.div`
  width: 41.5rem;
  position: absolute;
  top: var(--spacing-2xl);

  display: flex;
  justify-content: space-between;
`;

export const PurchaseButtonMobile = styled(RMButton)`
  margin-top: auto;
`;

// Cards
export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: var(--radius-round);
  border: 1px solid var(--border-hairline);
  transition: box-shadow 200ms;
  cursor: pointer;

  width: 100%;
  flex-direction: column;

  body[data-mobile='true'] & {
    justify-content: flex-start;
    width: 100%;
    flex-direction: row;
  }

  &:hover {
    box-shadow: var(--elevation-bottom-2);

    > div:first-child:before {
      opacity: 1;
    }
  }
`;

export const CardTop = styled.div`
  height: 17rem;
  min-width: 13.5rem;
  position: relative;
  border-radius: var(--radius-round) var(--radius-round) 0 0;
  background-color: var(--surface-dim);

  :before {
    opacity: 0;
    background-color: var(--darken-neutral-hover);
    transition: opacity 200ms;
    content: '';
    border-radius: var(--radius-round) var(--radius-round) 0 0;
    position: absolute;
    inset: 0;
  }

  body[data-mobile='true'] & {
    height: 100%;
    min-height: 7.75rem;
    min-width: 7rem;
    border-radius: var(--radius-round) 0 0 var(--radius-round);
  }
`;

export const CardBottom = styled.div`
  font-family: var(--font-sans);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-lg);
  color: var(--on-surface-primary);
  flex-grow: 1;

  body[data-mobile='true'] & {
    font-size: 14px;
    padding: var(--spacing-md);
    align-items: start;
  }
`;

export const CardTitle = styled.div`
  font-weight: 700;
  margin-bottom: var(--spacing-sm);
`;

export const CardText = styled.div`
  font-weight: 400;
  text-align: center;

  body[data-mobile='true'] & {
    text-align: left;
  }
`;

export const CardImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  body[data-mobile='true'] & {
    padding-top: var(--spacing-md);
    align-items: center;
  }
`;

export const CardImage = styled.img`
  width: 12rem;

  body[data-mobile='true'] & {
    width: 5rem;
  }
`;

export const BundleBanner = styled.div`
  display: flex;
  width: 100%;
  padding: var(--spacing-2xl);
  background-color: var(--color-spruce-20);
  border-radius: var(--radius-round);
  gap: var(--spacing-sm);

  body[data-mobile='true'] & {
    padding: var(--spacing-md) var(--spacing-xl);
    align-items: center;
  }
`;

export const BundleBannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  margin-right: auto;

  body[data-mobile='true'] & {
    gap: var(--spacing-2xs);
  }
`;

export const BundleBannerTItle = styled(RMText)`
  font-size: 1.25rem !important;

  body[data-mobile='true'] & {
    font-size: 0.875rem !important;
  }
`;

export const BundleBannerBUtton = styled(RMButton)`
  body[data-mobile='true'] & {
    min-width: 101px;
  }
`;
