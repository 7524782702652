import { RMInput } from '@/components/RMInput/RMInput';
import { RMSelect } from '@/components/RMSelect';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';
import { setInputValue, useInputValue } from '@/modules/form/input';

import { StorytellerOption } from '../../hooks/useStorytellerOptions';
import { ProjectCheckoutForm } from '../../project-checkout.form';
import { ProjectCheckoutEbookCheckbox } from '../ProjectCheckoutEbookCheckbox/ProjectCheckoutEbookCheckbox';

import { Container, Field, FormGrid } from './FreeProjectCheckoutDetailsForm.styles';

const QUANTITY_OPTIONS = Array.from({ length: 100 }).map((_, i) => ({
  label: `${i === 0 ? 'Select' : `${i} book${i > 1 ? 's' : ''} - $${59 * i} (save $${10 * i})`}`,
  value: String(i),
}));

export interface FreeProjectCheckoutDetailsFormProps {
  form: ProjectCheckoutForm;
  storytellers: StorytellerOption[];
}

export function FreeProjectCheckoutDetailsForm({ form, storytellers }: FreeProjectCheckoutDetailsFormProps) {
  const isMobile = useIsMobileViewport();
  const subscriptionOwnerPersonId = useInputValue(form, 'subscriptionOwnerPersonId');

  return (
    <Container>
      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Project details
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <InputController form={form} path="recipientPersonId">
          {({ value, onChange, onBlur }) => (
            <Field size="large">
              <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                Storyteller
              </RMText>
              <RMSpacer direction="column" spacing="xs" />

              <RMSelect
                id="storyteller-select"
                options={[{ label: 'Select', value: '' }, ...storytellers]}
                value={value ? `${value}-${subscriptionOwnerPersonId}` : ''}
                disabled={
                  value != null &&
                  storytellers.find(
                    (s) => s.personId == value && s.subscriptionOwnerPersonId == subscriptionOwnerPersonId,
                  ) != null
                }
                onChange={(newValue) => {
                  const storyteller = storytellers.find((s) => s.value == newValue);
                  onChange(storyteller?.personId ?? null);
                  onBlur();
                  setInputValue(form, 'subscriptionOwnerPersonId', storyteller?.subscriptionOwnerPersonId);
                }}
              />
            </Field>
          )}
        </InputController>
        <InputContainer form={form} path="name">
          {(props) => (
            <Field size="large">
              <RMInput
                id="project-name"
                label="Project name (you can change this later)"
                placeholder="Enter a name or topic for this project"
                {...props}
              />
            </Field>
          )}
        </InputContainer>

        <InputController form={form} path="products.books">
          {({ value, onChange, onBlur }) => (
            <Field size="large">
              <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                OPTIONAL: Purchase a hardcover book now and save
              </RMText>
              <RMSpacer direction="column" spacing="xs" />

              <RMSelect
                id="quantity-select"
                options={QUANTITY_OPTIONS}
                value={value != null ? String(value) : '1'}
                onChange={(newValue) => {
                  onChange(parseInt(newValue, 10));
                  onBlur();
                }}
              />
            </Field>
          )}
        </InputController>

        <InputController form={form} path="products.ebook">
          {({ value, onChange }) => (
            <Field size="large">
              <ProjectCheckoutEbookCheckbox value={!!value} onChange={onChange} />
            </Field>
          )}
        </InputController>
      </FormGrid>
    </Container>
  );
}
