import { ReactNode, useMemo } from 'react';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useInputValue } from '@/modules/form/input';
import { formatPhoneNumber } from '@/utils/phone-number';

import { ProjectCheckoutForm } from '../../project-checkout.form';

import { Container, Divider, EditButton, Link, Row } from './BabybookProjectCheckoutReview.styles';

export interface BabybookProjectCheckoutReviewProps {
  form: ProjectCheckoutForm;
  onEditHostInfo: () => void;
  onEditProjectName: () => void;
  CheckoutSummary: ReactNode;
}

export function BabybookProjectCheckoutReview({
  form,
  onEditHostInfo,
  onEditProjectName,
  CheckoutSummary,
}: BabybookProjectCheckoutReviewProps) {
  const isMobile = useIsMobileViewport();

  const rawOwnerPhone = useInputValue(form, 'owner.phone');
  const ownerPhone = useMemo(() => formatPhoneNumber(rawOwnerPhone), [rawOwnerPhone]);

  const subjectFirstName = useInputValue(form, 'subject.firstName');
  const projectName = useInputValue(form, 'name');
  const ownerFirstName = useInputValue(form, 'owner.person.firstName');
  const ownerLastName = useInputValue(form, 'owner.person.lastName');
  const ownerEmail = useInputValue(form, 'owner.email');
  const productBooks = useInputValue(form, 'products.books');
  const productEbook = useInputValue(form, 'products.ebook');

  return (
    <Container>
      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Review your order
      </RMText>
      <RMSpacer spacing="xl" direction="column" />

      {isMobile && (
        <>
          {CheckoutSummary}
          <RMSpacer spacing="xl" direction="column" />
        </>
      )}

      <Row>
        <RMText type="serif" size="m" color="on-surface-primary">
          Your profile
        </RMText>
        <EditButton onClick={onEditHostInfo}>
          <RMText type="sans" size="xs" color="primary" bold underline>
            Edit
          </RMText>
          <FontAwesomeIcon icon={faEdit} fontSize={16} />
        </EditButton>
      </Row>
      <RMSpacer spacing="md" direction="column" />
      <RMText type="sans" size="s" color="on-surface-secondary">
        Your name:{' '}
        <b>
          {ownerFirstName} {ownerLastName}
        </b>
      </RMText>
      <RMSpacer spacing="2xs" direction="column" />
      <RMText type="sans" size="s" color="on-surface-secondary">
        Your email: <b>{ownerEmail}</b>
      </RMText>
      {ownerPhone && (
        <>
          <RMSpacer spacing="2xs" direction="column" />
          <RMText type="sans" size="s" color="on-surface-secondary">
            Your phone: <b>{ownerPhone}</b>
          </RMText>
        </>
      )}
      {subjectFirstName ? (
        <>
          <RMSpacer spacing="2xs" direction="column" />
          <RMText type="sans" size="s" color="on-surface-secondary">
            Your baby's name:{' '}
            <b>
              {subjectFirstName} {ownerLastName}
            </b>
          </RMText>
        </>
      ) : null}

      <Divider />

      <Row>
        <RMText type="serif" size="m" color="on-surface-primary">
          Your Book
        </RMText>
        <EditButton onClick={onEditProjectName}>
          <RMText type="sans" size="xs" color="primary" bold underline>
            Edit
          </RMText>
          <FontAwesomeIcon icon={faEdit} fontSize={16} />
        </EditButton>
      </Row>
      <RMSpacer spacing="md" direction="column" />
      <RMText type="sans" size="s" color="on-surface-secondary">
        Book title: <b>{projectName}</b>
      </RMText>
      <RMSpacer spacing="2xs" direction="column" />
      <RMText type="sans" size="s" color="on-surface-secondary">
        Number of books: <b>{productBooks}</b>
      </RMText>
      <RMSpacer spacing="2xs" direction="column" />
      <RMText type="sans" size="s" color="on-surface-secondary">
        Add E-book: <b>{productEbook ? 'Yes' : 'No'}</b>
      </RMText>

      <Divider />

      <RMText type="sans" size="xs" color="on-surface-primary">
        The privacy of your content is critical to us. By purchasing, you acknowledge that you have read and accept
        Remento’s{' '}
        <Link
          href="https://app.termly.io/document/terms-of-use-for-saas/a8cc7ba3-01da-4175-8040-c0059abd3c26"
          target="_blank"
        >
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href="https://app.termly.io/document/privacy-policy/0755769b-4d9f-4a87-b6e7-afd34802edc2" target="_blank">
          Privacy Policy
        </Link>{' '}
        and consent to receiving occasional marketing emails from us. Note that all shipments outside the US will incur
        an additional shipping & handling fee according to our{' '}
        <Link href="https://intercom.help/remento/en/articles/8365901-where-can-remento-ship-to" target="_blank">
          shipping terms
        </Link>
        .
      </RMText>
    </Container>
  );
}
