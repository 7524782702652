import { useMemo } from 'react';

import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useInputValue } from '@/modules/form/input';
import { usePersonQuery } from '@/services/api/person';
import { genitiveCase } from '@/utils/genitiveCase';

import { ProjectCheckoutSummaryDesktop } from '../components/ProjectCheckoutSummary/ProjectCheckoutSummary.desktop';
import { ProjectCheckoutSummaryMobile } from '../components/ProjectCheckoutSummary/ProjectCheckoutSummary.mobile';
import { ProjectCheckoutSummaryMobileSticky } from '../components/ProjectCheckoutSummary/ProjectCheckoutSummary.mobile.sticky';
import { IncludeItem } from '../components/ProjectCheckoutSummary/ProjectCheckoutSummary.types';
import { ProjectCheckoutManager, useCheckoutTotal } from '../project-checkout.manager';

export interface ProjectCheckoutSummaryContainerProps {
  manager: ProjectCheckoutManager;
  sticky?: boolean;
}

export function ProjectCheckoutSummaryContainer({ manager, sticky = false }: ProjectCheckoutSummaryContainerProps) {
  const isMobile = useIsMobileViewport();

  const projectType = useInputValue(manager.form, 'type');
  const total = useCheckoutTotal(manager);

  const storytellerPersonId = useInputValue(manager.form, 'recipientPersonId');
  const storytellerPersonQuery = usePersonQuery(storytellerPersonId);

  const title = useMemo(() => {
    switch (projectType) {
      case 'BIOGRAPHY':
      case 'AUTOBIOGRAPHY': {
        return 'The Remento Book';
      }
      case 'BABYBOOK': {
        return 'The Remento Baby Book';
      }
      case 'FREE': {
        return storytellerPersonQuery.data?.name
          ? `${genitiveCase(storytellerPersonQuery.data.name.first)} New Project`
          : 'New Project';
      }
    }

    return '';
  }, [projectType, storytellerPersonQuery.data?.name]);

  const includes = useMemo<IncludeItem[]>(() => {
    const freeShippingItem: IncludeItem = {
      message: 'Free US shipping',
      tooltip: (
        <RMText type="sans" size="xxs" color="inverse-on-surface-primary">
          International shipping to the UK, Canada, Ireland, Australia, and New Zealand is available for an additional
          fee. Shipping fees will be charged separately when your book is ready to print. Rates vary by
          destination.&nbsp;
          <a href="https://intercom.help/remento/en/articles/8365901-where-can-remento-ship-to" target="_blank">
            <RMText type="sans" size="xxs" color="inverse-on-surface-primary" underline>
              Learn more
            </RMText>
          </a>
          .
        </RMText>
      ),
    };

    switch (projectType) {
      case 'BIOGRAPHY':
      case 'AUTOBIOGRAPHY': {
        return [
          { message: '1 year of weekly prompts' },
          { message: `${total.products.books} hardcover color-printed ${total.products.books > 1 ? 'books' : 'book'}` },
          ...(total.products.ebook ? [{ message: 'E-book included' }] : []),
          freeShippingItem,
        ];
      }
      case 'BABYBOOK': {
        return [
          {
            message: `${total.products.books} hard-cover color-printed ${
              total.products.books > 1 ? 'books' : 'book'
            } with up to 200 pages`,
          },
          { message: 'Weekly reminders to record' },
          { message: 'Unlimited collaborators' },
          ...(total.products.ebook ? [{ message: 'E-book included' }] : []),
          freeShippingItem,
        ];
      }
      case 'FREE': {
        return [
          ...(total.products.books > 0
            ? [
                {
                  message: `${total.products.books} hardcover color-printed ${
                    total.products.books > 1 ? 'books' : 'book'
                  }`,
                },
              ]
            : []),
          ...(total.products.ebook ? [{ message: '1 e-book' }] : []),
          { message: 'Unlimited recordings' },
          { message: 'Weekly reminders to record' },
          ...(total.products.books > 0 ? [freeShippingItem] : []),
        ];
      }
    }

    return [];
  }, [total.products.books, total.products.ebook, projectType]);

  if (projectType == null) {
    throw new Error('project-type-cannot-be-null');
  }

  if (isMobile) {
    if (sticky) {
      return <ProjectCheckoutSummaryMobileSticky type={projectType} title={title} total={total} includes={includes} />;
    }
    return <ProjectCheckoutSummaryMobile type={projectType} title={title} total={total} includes={includes} />;
  }

  return <ProjectCheckoutSummaryDesktop type={projectType} title={title} total={total} includes={includes} />;
}
