import { createContext, PropsWithChildren, useContext } from 'react';

import { ProjectCheckoutManager } from './project-checkout.manager';

const ProjectCheckoutManagerContext = createContext<ProjectCheckoutManager | null>(null);

export type ProjectCheckoutManagerProviderProps = PropsWithChildren<{
  value: ProjectCheckoutManager;
}>;

export function ProjectCheckoutManagerProvider({ value, children }: ProjectCheckoutManagerProviderProps) {
  return <ProjectCheckoutManagerContext.Provider value={value}>{children}</ProjectCheckoutManagerContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useProjectCheckoutManager(): ProjectCheckoutManager {
  const context = useContext(ProjectCheckoutManagerContext);
  if (!context) {
    throw new Error('useProjectCheckoutManager must be used inside a ProjectCheckoutManagerProvider');
  }
  return context;
}
