import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledNewProjectPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

export const Header = styled.div`
  min-height: 5rem;
  padding: 0 var(--spacing-3xl);
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  body[data-mobile='true'] & {
    padding: var(--spacing-lg) var(--spacing-md);
    min-height: 0;
  }
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const Divider = styled.div`
  flex: 0 0 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-2xl);
  padding: var(--spacing-4xl) var(--spacing-xl);

  body[data-mobile='true'] & {
    /* 81px is the footer height */
    padding: var(--spacing-md) var(--spacing-md) calc(81px + var(--spacing-2xl));
  }
`;

export const PickerWrapper = styled.div`
  max-width: 390px;
  width: 100%;
`;

export const ContinueButton = styled(RMButton)`
  max-width: 250px;
  width: 100%;

  body[data-mobile='true'] & {
    max-width: 100%;
  }
`;

export const Link = styled(RMText)`
  cursor: pointer;
  text-decoration: underline;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--surface);
  border-top: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-top-2);
`;
