import { Fragment } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { Card, CardContent, CardHeader, Content, Image } from './ProjectCheckoutSummary.mobile.sticky.styles';
import { ProjectCheckoutSummaryProps } from './ProjectCheckoutSummary.types';
import { getProjectCheckoutSummaryImage } from './ProjectCheckoutSummary.utils';

export function ProjectCheckoutSummaryMobileSticky({ type, title, total, includes }: ProjectCheckoutSummaryProps) {
  const image = getProjectCheckoutSummaryImage(type, total.products);

  return (
    <Card>
      <CardHeader>
        <RMText type="sans" size="xs" bold color="on-surface-primary">
          {title}
        </RMText>
        <RMText type="sans" size="xs" bold={total.status === 'fetched'} color="on-surface-secondary">
          {total.status === 'fetching' ? 'Loading...' : `$${total.price}`}
        </RMText>
      </CardHeader>
      <CardContent>
        <Image src={image.src} alt="book" data-portrait={image.portrait} />
        <Content>
          {includes.map((productInclude) => (
            <Fragment key={productInclude.message}>
              <RMSpacer spacing="xs" direction="column" />
              <RMStack spacing="xs" direction="row" center>
                <FontAwesomeIcon size="xs" icon={faCheck} color="var(--on-surface-primary)" />
                <RMText type="sans" size="xxs" color="on-surface-primary">
                  {productInclude.message}
                </RMText>
                {productInclude.tooltip != null && (
                  <RMTooltip showOnTouch message={productInclude.tooltip} side="bottom">
                    <FontAwesomeIcon icon={faCircleExclamation} size="sm" color={ColorsTokens.primary} />
                  </RMTooltip>
                )}
              </RMStack>
            </Fragment>
          ))}
        </Content>
      </CardContent>
    </Card>
  );
}
