import { styled } from '@linaria/react';

import { EmbeddedTypeform } from '@/components/EmbeddedTypeform/EmbeddedTypeform';

export const StyledEmbeddedTypeForm = styled(EmbeddedTypeform)`
  width: 100dvw;
  height: 100dvh;

  & iframe {
    border-radius: 0 !important;
    width: 100dvw !important;
    height: 100dvh !important;
  }
`;
