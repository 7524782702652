import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Header = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '0 var(--spacing-lg)' : '0 var(--spacing-3xl)')};
  height: ${(props) => (props.isMobile ? '3.5rem' : '5rem')};
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;
  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const ScrollArea = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export const Body = styled.div<{ centered: boolean }>`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: ${(props) => (props.centered ? 'center' : 'unset')};
  padding: var(--spacing-2xl) var(--spacing-3xl);
  max-width: 550px;
  margin: 0 auto;

  > button {
    flex: unset;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: var(--spacing-md);
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--surface);
  border-top: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-top-2);
  width: 100%;
  margin-top: auto;
`;
