import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { CheckboxContent, Content, Message } from './ProjectCheckoutEbookCheckbox.styles';

interface ProjectCheckoutEbookCheckboxProps {
  onChange: (value: boolean) => void;
  value: boolean;
}

export function ProjectCheckoutEbookCheckbox({ onChange, value = false }: ProjectCheckoutEbookCheckboxProps) {
  return (
    <Content onClick={() => onChange(!value)}>
      <CheckboxContent>
        <RMCheckbox square size="sm" checked={value} />
        <RMSpacer direction="row" spacing="xs" />
        <RMText type="sans" size="s" bold color="on-surface-primary">
          Add e-book
        </RMText>
        <RMSpacer direction="row" spacing="3xl" />
        <RMText type="sans" size="s" bold color="on-surface-primary">
          $24.99
        </RMText>
        <RMSpacer direction="row" spacing="sm" />
        <RMText style={{ textDecoration: 'line-through' }} type="sans" size="xs" color="on-surface-tertiary">
          $49.99{' '}
        </RMText>
      </CheckboxContent>
      <Message type="sans" size="xs" color="on-surface-primary">
        Expand the reach of your stories even further. Choose the eBook option to have your book available in digital
        formats (PDF, Kindle, Epub, etc.). Save 50% by purchasing in advance.
      </Message>
    </Content>
  );
}
