import { useEffect } from 'react';

import { useInputValue } from '@/modules/form/input';
import { usePersonQuery } from '@/services/api/person';

import { FreeProjectCheckoutReviewProps } from '../components/FreeProjectCheckoutReview/FreeProjectCheckoutReview';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

import { ProjectCheckoutSummaryContainer } from './ProjectCheckoutSummary.container';

export function FreeProjectCheckoutReviewStepContainer() {
  const manager = useProjectCheckoutManager();

  const storytellerPersonId = useInputValue(manager.form, 'recipientPersonId');
  const storytellerPersonQuery = usePersonQuery(storytellerPersonId);

  useEffect(() => {
    goToStep(manager, 'review');
  }, [manager]);

  return (
    <FreeProjectCheckoutReviewProps
      form={manager.form}
      storytellerName={storytellerPersonQuery.data?.name?.full ?? 'N/A'}
      onEditProjectInfo={() => goToStep(manager, 'details')}
      CheckoutSummary={<ProjectCheckoutSummaryContainer manager={manager} sticky />}
    />
  );
}
