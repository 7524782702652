import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const Content = styled.div`
  border-radius: var(--radius-regular);
  border: 1px solid var(--border-strong);
  padding: var(--spacing-md);
  cursor: pointer;

  :hover {
    border: 1px solid var(--border-stronger);
  }
`;

export const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
`;

export const Message = styled(RMText)`
  display: block;
  padding: var(--spacing-md) 1.375rem 0 1.375rem;
`;
