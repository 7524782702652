import { MarketingMetadata } from '@remento/types/marketing';
import { PersonName } from '@remento/types/person';
import { SubscriptionType, UserPhone } from '@remento/types/user';

export interface BaseCheckoutRequest extends MarketingMetadata {
  owner: {
    person: {
      firstName: string;
      lastName: string;
    };
    email: string;
    phone?: UserPhone | null;
  };
  credits: {
    books: number;
    ebook: boolean;
  };
}

export interface CheckoutBiographyRequest extends BaseCheckoutRequest {
  type: CheckoutType.BIOGRAPHY;
  recipient: {
    person: {
      firstName: string;
      lastName: string;
    };
    email: string;
    phone?: UserPhone | null;
  };
  gift: {
    from: string;
    message: string;
    sendOn: number;
  };
}

export interface CheckoutAutobiographyRequest extends BaseCheckoutRequest {
  type: CheckoutType.AUTOBIOGRAPHY;
}

export interface CheckoutBabyRequest extends BaseCheckoutRequest {
  type: CheckoutType.BABYBOOK;
  name: string;
  subject: {
    firstName: string;
    lastName: string;
  };
}

export type CheckoutRequest = CheckoutBiographyRequest | CheckoutAutobiographyRequest | CheckoutBabyRequest;

export interface BundleCheckoutRequest extends MarketingMetadata {
  typeformFormId: string;
  typeformResponseId: string;
}

export interface Checkout {
  purchaserUserRuid: string;
  checkoutId: string;
  paymentUrl: string;
}

export interface CheckoutSession {
  id: string;
  invoiceNumber: string | null;
  couponId: string | null;
  buyerEmail: string;
  storytellerName: PersonName | null;
  subjectName: PersonName | null;
  price: number;
  paid: number;
}

export enum CheckoutType {
  BIOGRAPHY = 'BIOGRAPHY',
  AUTOBIOGRAPHY = 'AUTOBIOGRAPHY',
  BABYBOOK = 'BABYBOOK',
  BOOK = 'BOOK',
}

export interface CheckoutTotalRequest {
  projectType: 'BIOGRAPHY' | 'AUTOBIOGRAPHY' | 'BABYBOOK' | 'FREE';
  ebook: boolean;
  books: number;
  coupon: string | null | undefined;
}

export interface CheckoutTotalResponse {
  total: number;
  coupon: string | null;
  isCouponValid: boolean;
}

export interface CheckoutService {
  checkout(request: CheckoutRequest): Promise<Checkout>;
  bundleCheckout(request: BundleCheckoutRequest): Promise<Checkout>;

  reactivate(type: SubscriptionType): Promise<Checkout>;
  earlyRenew(): Promise<Checkout>;

  getCheckoutTotal(request: CheckoutTotalRequest): Promise<CheckoutTotalResponse>;
  getCheckoutSession(id: string): Promise<CheckoutSession>;
}
