import { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faGift } from '@fortawesome/pro-solid-svg-icons';

import BabyBookSrc from '@/assets/remento-baby-book.webp';
import BiographyBookSrc from '@/assets/remento-book.webp';
import WordmarkSrc from '@/assets/wordmark.svg';
import WordmarkPrimarySrc from '@/assets/wordmark-primary.svg';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getCheckoutAudiencePath, getCheckoutBundlePath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { CheckoutType } from '@/services/api/checkout';

import {
  Body,
  BundleBanner,
  BundleBannerBUtton,
  BundleBannerTextWrapper,
  BundleBannerTItle,
  CardBottom,
  CardImage,
  CardImageWrapper,
  CardsContainer,
  CardText,
  CardTitle,
  CardTop,
  CardWrapper,
  Header,
  HeaderLogo,
  MobileHeader,
  StyledProjectCheckoutSelection,
  TextContainer,
} from './ProjectCheckoutPage.styles';

function InternalProjectCheckoutPage() {
  const { checkoutAnalyticsService } = useServices();
  const navigate = useNavigate();

  const isMobile = useIsMobileViewport();

  const handleSelect = useCallback(
    async (checkoutType: CheckoutType) => {
      let project: 'biography' | 'baby';
      switch (checkoutType) {
        case CheckoutType.BABYBOOK:
          project = 'baby';
          break;
        case CheckoutType.BIOGRAPHY:
        case CheckoutType.AUTOBIOGRAPHY:
        default:
          project = 'biography';
          break;
      }

      navigate(getCheckoutAudiencePath(project));
    },
    [navigate],
  );

  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived('book');
  }, [checkoutAnalyticsService]);

  return (
    <StyledProjectCheckoutSelection>
      {isMobile ? (
        <MobileHeader>
          <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
        </MobileHeader>
      ) : (
        <Header>
          <HeaderLogo src={WordmarkPrimarySrc} alt="Remento wordmark" />
        </Header>
      )}

      <Body>
        <TextContainer>
          <RMText size={isMobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
            Which book would you like to create?
          </RMText>
        </TextContainer>
        <CardsContainer>
          <CardWrapper onClick={() => handleSelect(CheckoutType.BIOGRAPHY)}>
            <CardTop>
              <CardImageWrapper>
                <CardImage src={BiographyBookSrc} />
              </CardImageWrapper>
            </CardTop>
            <CardBottom>
              <CardTitle>The Remento Book</CardTitle>
              <CardText>Turn memories of the past into a book of written stories.</CardText>
            </CardBottom>
          </CardWrapper>

          <CardWrapper onClick={() => handleSelect(CheckoutType.BABYBOOK)}>
            <CardTop>
              <CardImageWrapper>
                <CardImage src={BabyBookSrc} />
              </CardImageWrapper>
            </CardTop>
            <CardBottom>
              <CardTitle>The Remento Baby Book</CardTitle>
              <CardText>Capture your baby’s earliest moments, and the memory behind each one.</CardText>
            </CardBottom>
          </CardWrapper>
        </CardsContainer>

        <BundleBanner>
          <BundleBannerTextWrapper>
            <BundleBannerTItle type="sans" size="m" bold={isMobile ? true : 'medium'} color="white">
              Buy one, get one 50% off
            </BundleBannerTItle>
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="white">
              Give Remento to two people and get our best deal of the season.
            </RMText>
          </BundleBannerTextWrapper>
          <Link to={getCheckoutBundlePath()}>
            <BundleBannerBUtton background="outlined" color="white" leftIcon={isMobile ? null : faGift}>
              Buy now
            </BundleBannerBUtton>
          </Link>
        </BundleBanner>
      </Body>
    </StyledProjectCheckoutSelection>
  );
}

export function ProjectCheckoutPage() {
  return (
    <RementoPage type="empty">
      <InternalProjectCheckoutPage />
    </RementoPage>
  );
}
