import { styled } from '@linaria/react';

export const Summary = styled.div`
  position: relative;
  border-top: 1px solid var(--border-weak);
  border-bottom: 2px solid var(--color-spruce-40-16);
  background-color: var(--color-spruce-40-8);
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: var(--spacing-md);
`;

export const HeaderContent = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  > svg {
    height: 16px;
    width: 16px;
    transition: all 250ms;
    rotate: ${(props) => (props.open ? '90deg' : '0')};
  }
`;

export const Absolute = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  z-index: 100;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  padding: var(--spacing-md);
  border-bottom: 2px solid var(--border-weak);
  background-color: var(--surface);

  &:before {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-spruce-40-8);
    pointer-events: none;
  }
`;

export const Image = styled.img`
  width: 180px;
  margin-bottom: calc(var(--spacing-md) * -1);

  &[data-portrait='true'] {
    width: 6.75rem;
    padding-bottom: var(--spacing-2xl);
    filter: drop-shadow(-8px 0px 8px rgba(0, 0, 0, 0.25)) drop-shadow(-11px 4px 20px rgba(0, 0, 0, 0.25));
  }
`;
