import { useEffect } from 'react';

import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

import { BabybookProjectCheckoutContactForm } from '../components/BabybookProjectCheckoutContactForm/BabybookProjectCheckoutContactForm';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

export function BabybookProjectCheckoutContactStepContainer() {
  const manager = useProjectCheckoutManager();
  const user = useUser();

  const userPersonQuery = usePersonQuery(user?.personId);

  useEffect(() => {
    goToStep(manager, 'contact');
  }, [manager]);

  return (
    <BabybookProjectCheckoutContactForm
      form={manager.form}
      firstNameDisabled={userPersonQuery.data?.name != null}
      lastNameDisabled={userPersonQuery.data?.name != null}
      emailDisabled={user?.communicationChannels.email != null}
      phoneDisabled={user?.communicationChannels.email != null}
    />
  );
}
