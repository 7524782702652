import { PropsWithChildren, ReactNode } from 'react';
import { faGift } from '@fortawesome/pro-solid-svg-icons';
import { PersonName } from '@remento/types/person';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';

import { ProjectCheckoutLoginLink } from '../ProjectCheckoutLoginLink/ProjectCheckoutLoginLink';
import { ProjectCheckoutLogoutLink } from '../ProjectCheckoutLogoutLink/ProjectCheckoutLogoutLink';

import {
  Container,
  Divider,
  Header,
  Left,
  LeftForm,
  LeftFormButton,
  Main,
  MainContent,
  Right,
  StepperWrapper,
  Wordmark,
} from './ProjectCheckoutDesktopLayout.styles';

export interface SignedInUser {
  name: PersonName | null;
  email: string;
}

type ProjectCheckoutDesktopLayoutProps = PropsWithChildren<{
  Stepper: ReactNode;
  RightPanel: ReactNode;
  ContinueButton: ReactNode;
  user: SignedInUser | null;
  showPurchaseGiftCard?: boolean;
  onSignIn: () => void;
  onSignOut: (redirect?: boolean) => Promise<void>;
  onPurchaseGiftCard?: () => void;
}>;

export function ProjectCheckoutDesktopLayout({
  Stepper,
  RightPanel,
  ContinueButton,
  user,
  showPurchaseGiftCard = true,
  onSignIn,
  onSignOut,
  onPurchaseGiftCard,
  children,
}: ProjectCheckoutDesktopLayoutProps) {
  return (
    <Container>
      <Header data-center={showPurchaseGiftCard == false}>
        <Wordmark destination="marketing" />
        {showPurchaseGiftCard && (
          <RMButton leftIcon={faGift} background="outlined" onClick={onPurchaseGiftCard}>
            Purchase gift card
          </RMButton>
        )}
      </Header>
      <Divider />
      <Main>
        <MainContent>
          <Left>
            <LeftForm>
              <StepperWrapper>{Stepper}</StepperWrapper>
              <RMSpacer spacing="3xl" direction="column" />
              {children}
              <RMSpacer spacing="2xl" direction="column" />
              <LeftFormButton>{ContinueButton}</LeftFormButton>
              {user ? (
                <>
                  <RMSpacer spacing="xl" direction="column" />
                  <ProjectCheckoutLogoutLink onSignOut={onSignOut} user={user} />
                </>
              ) : (
                <>
                  <RMSpacer spacing="xl" direction="column" />
                  <ProjectCheckoutLoginLink onClick={onSignIn} />
                </>
              )}
            </LeftForm>
          </Left>
          <Right>{RightPanel}</Right>
        </MainContent>
      </Main>
    </Container>
  );
}
