import { useCallback } from 'react';
import pRetry from 'p-retry';

import { TypeformSubmitEvent } from '@/components/EmbeddedTypeform/EmbeddedTypeform';
import { toast } from '@/components/RMToast/RMToast';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';
import { getMarketingCookies } from '@/utils/marketing-cookies';

import { StyledEmbeddedTypeForm } from './CheckoutBundlePage.styles';

function InternalCheckoutBundlePage() {
  const { checkoutService } = useServices();

  const handleSubmit = useCallback(
    async (e: TypeformSubmitEvent) => {
      try {
        // Sometimes, after submitting a form, for some reason it can take sometime to the form be available
        // to be fetched by the API.
        // We should keep retrying until the form is available.
        await pRetry(async () => {
          const marketingCookies = getMarketingCookies();
          const checkout = await checkoutService.bundleCheckout({
            ...marketingCookies,
            typeformFormId: e.formId,
            typeformResponseId: e.responseId,
          });
          window.open(checkout.paymentUrl, '_self');
        });
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    },
    [checkoutService],
  );

  return <StyledEmbeddedTypeForm formId={import.meta.env.VITE_BUNDLE_FORM_ID} fullScreen onSubmit={handleSubmit} />;
}

export function CheckoutBundlePage() {
  return (
    <RementoPage type="empty">
      <InternalCheckoutBundlePage />
    </RementoPage>
  );
}
