import { useEffect } from 'react';

import { BabybookProjectCheckoutReview } from '../components/BabybookProjectCheckoutReview/BabybookProjectCheckoutReview';
import { goToStep } from '../project-checkout.manager';
import { useProjectCheckoutManager } from '../project-checkout.manager.context';

import { ProjectCheckoutSummaryContainer } from './ProjectCheckoutSummary.container';

export function BabybookProjectCheckoutReviewStepContainer() {
  const manager = useProjectCheckoutManager();

  useEffect(() => {
    goToStep(manager, 'review');
  }, [manager]);

  return (
    <BabybookProjectCheckoutReview
      form={manager.form}
      onEditHostInfo={() => goToStep(manager, 'contact')}
      onEditProjectName={() => goToStep(manager, 'details')}
      CheckoutSummary={<ProjectCheckoutSummaryContainer manager={manager} sticky />}
    />
  );
}
