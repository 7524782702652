import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Image = styled.img`
  width: 18.5rem;

  &[data-portrait='true'] {
    width: 6.75rem;
    padding-bottom: var(--spacing-2xl);
    filter: drop-shadow(-8px 0px 8px rgba(0, 0, 0, 0.25)) drop-shadow(-11px 4px 20px rgba(0, 0, 0, 0.25));
  }
`;
